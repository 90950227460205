import { inject, Injectable } from '@angular/core';
import { AccessSetupApiService } from './access-setup-api.service';
import { Observable } from 'rxjs';
import { ContentAccessGrant } from './domain/content-access-grant';
import { CommunityMembershipAccessGrant } from './domain/community-membership-access-grant';
import { HttpClient } from '@angular/common/http';
import { UserApi } from './user-api';
import { RootResourceId } from '../../community/domain/resource';
import { AddToCommunity } from './domain/add-to-community';

@Injectable({
  providedIn: 'root'
})
export class AccessSetupApiServiceImplementation extends AccessSetupApiService {
  private readonly httpClient = inject(HttpClient);

  override grantAccessToFeed(contentElement: ContentAccessGrant): Observable<void> {
    return this.httpClient.post<void>(UserApi.grantAccessToFeed, contentElement);
  }

  override grantAccessToCollection(contentElement: ContentAccessGrant): Observable<void> {
    return this.httpClient.post<void>(UserApi.grantAccessToCollection, contentElement);
  }

  override assignSubscription(subscription: CommunityMembershipAccessGrant): Observable<void> {
    return this.httpClient.post<void>(UserApi.assignSubscription, subscription);
  }

  override addCreatorToCommunity(userEmail: string, communityId: RootResourceId): Observable<void> {
    const data: AddToCommunity = {
      userEmail,
      communityId
    }
    return this.httpClient.post<void>(UserApi.addToCommunity, data);
  }
}
