import {Observable} from 'rxjs';
import {BundlePurchaseInfo} from './domain/bundle-purchase-info';
import {BundleSubscriptionInfo} from './domain/bundle-subscription-info';
import {MembershipSubscriptionInfo} from './domain/membership-subscription-info';
import {PurchaseManagementApiService} from './purchase-management-api.service';
import {CommunityApi} from "../../community/community.api";
import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PurchaseManagementApiServiceImplementation extends PurchaseManagementApiService {

  public constructor(private httpClient: HttpClient) {
    super();
  }

  override getCommunityMemberships(): Observable<MembershipSubscriptionInfo[]> {
    return this.httpClient.get<MembershipSubscriptionInfo[]>(CommunityApi.membershipSubscriptions());
  }

  override getContentSubscriptions(): Observable<BundleSubscriptionInfo[]> {
    return this.httpClient.get<BundleSubscriptionInfo[]>(CommunityApi.bundleSubscriptions());
  }

  override getContentOneTimePurchases(): Observable<BundlePurchaseInfo[]> {
    return this.httpClient.get<BundlePurchaseInfo[]>(CommunityApi.bundlePurchases());
  }

  override cancelCommunityMembership(trackingId: string): Observable<void> {
    return this.httpClient.post<void>(CommunityApi.cancelSubscription(), trackingId);
  }

  override cancelContentSubscription(trackingId: string): Observable<void> {
    return this.httpClient.post<void>(CommunityApi.cancelSubscription(), trackingId);
  }

  override reactivateCommunityMembership(trackingId: string, expiry?: number | undefined): Observable<void> {
    return this.httpClient.post<void>(CommunityApi.reactivateSubscription(), trackingId);
  }

  override reactivateContentSubscription(trackingId: string, expiry?: number | undefined): Observable<void> {
    return this.httpClient.post<void>(CommunityApi.reactivateSubscription(), trackingId);
  }
}
