import {environment} from "../../environments/environment";
import {ResourceId, RootResourceId} from "./domain/resource";
import {UUID} from "./community.routes";

export namespace CommunityApi {

  export function getCommunityProfiles(): string {
    return profile();
  }

  export function getCommunityProfile(creationTimestamp: number, communityId: string): string {
    return profile() + '/' + creationTimestamp + '/' + communityId;
  }

  export function createCommunityProfile(): string {
    return profile();
  }

  export function putCommunityProfile(creationTimestamp: number, communityId: string): string {
    return profile() + '/' + creationTimestamp + '/' + communityId;
  }

  function profile() {
    return resource('profile');
  }

  function community(): string {
    return environment.apiPath + '/community';
  }

  function resource(resourceType: string): string {
    return community() + '/' + resourceType;
  }

  function assignment(resourceType: string, assignmentId: string): string {
    return resource(resourceType) + '/' + assignmentId;
  }

  export function getBundleProducts(communityId: string, fromTimestamp: number): string {
    return bundleProducts(communityId) + '/' + fromTimestamp;
  }

  export function getBundleProduct(resourceId: ResourceId): string {
    return bundleProduct(resourceId.assignmentId, resourceId.creationTimestamp, resourceId.uuid);
  }

  export function createBundleProduct(communityId: string): string {
    return bundleProducts(communityId);
  }

  export function putBundleProduct(communityId: string, creationTimestamp: number, id: string): string {
    return bundleProduct(communityId, creationTimestamp, id);
  }

  function bundleProduct(communityId: string, creationTimestamp: number, id: string): string {
    return bundleProducts(communityId) + '/' + creationTimestamp + '/' + id;
  }

  function bundleProducts(communityId: string): string {
    return products('bundle', communityId);
  }

  export function getMembershipProducts(communityId: string, fromTimestamp: number): string {
    return membershipProducts(communityId) + '/' + fromTimestamp;
  }

  export function getMembershipProduct(resourceId: ResourceId): string {
    return membershipProduct(resourceId.assignmentId, resourceId.creationTimestamp, resourceId.uuid);
  }

  export function createMembershipProduct(communityId: string): string {
    return membershipProducts(communityId);
  }

  export function putMembershipProduct(communityId: string, creationTimestamp: number, id: string): string {
    return membershipProduct(communityId, creationTimestamp, id);
  }

  function membershipProduct(communityId: string, creationTimestamp: number, id: string): string {
    return membershipProducts(communityId) + '/' + creationTimestamp + '/' + id;
  }

  function membershipProducts(communityId: string): string {
    return products('membership', communityId);
  }

  function products(kind: string, communityId: string): string {
    return assignment('product' + '/' + kind, communityId);
  }

  export function mayPurchaseProduct(resourceId: ResourceId): string {
    return resource('product/bundle') + '/may-purchase/' + resourceId.assignmentId + '/' + resourceId.creationTimestamp + '/' + resourceId.uuid;
  }

  export function productByContent(resourceId: ResourceId): string {
    return resource('product/bundle') + '/by-content/' + resourceId.assignmentId + '/' + resourceId.creationTimestamp + '/' + resourceId.uuid;
  }

  export function getFeeds(communityId: string, fromTimestamp: number): string {
    return feeds(communityId) + '/' + fromTimestamp;
  }

  export function getFeed(resourceId: ResourceId): string {
    return feed(resourceId.assignmentId, resourceId.creationTimestamp, resourceId.uuid);
  }

  export function createFeed(communityId: string): string {
    return feeds(communityId);
  }

  export function putFeed(communityId: string, creationTimestamp: number, id: string): string {
    return feed(communityId, creationTimestamp, id);
  }

  function feed(communityId: string, creationTimestamp: number, id: string): string {
    return feeds(communityId) + '/' + creationTimestamp + '/' + id;
  }

  function feeds(communityId: string): string {
    return assignment('feed', communityId);
  }

  export function getCollections(communityId: string, fromTimestamp: number): string {
    return collections(communityId) + '/' + fromTimestamp;
  }

  export function getCollection(resourceId: ResourceId): string {
    return collection(resourceId.assignmentId, resourceId.creationTimestamp, resourceId.uuid);
  }

  export function createCollection(communityId: string): string {
    return collections(communityId);
  }

  export function putCollection(communityId: string, creationTimestamp: number, id: string): string {
    return collection(communityId, creationTimestamp, id);
  }

  function collection(communityId: string, creationTimestamp: number, id: string): string {
    return collections(communityId) + '/' + creationTimestamp + '/' + id;
  }

  function collections(communityId: string): string {
    return assignment('collection', communityId);
  }

  export function getPost(resourceId: ResourceId) {
    return posts(resourceId.assignmentId, resourceId.creationTimestamp) + '/' + resourceId.uuid;
  }

  export function posts(assignmentId: string, timestamp: number) {
    return post(assignmentId) + '/' + timestamp;
  }

  export function post(assignmentId: string) {
    return assignment('post', assignmentId);
  }

  export function accessControl(resourceId: ResourceId) {
    return environment.apiPath + '/access-control' + '/' + resourceId.assignmentId + '/' + resourceId.creationTimestamp + '/' + resourceId.uuid;
  }

  export function mayAccess(resourceId: ResourceId) {
    return environment.apiPath + '/access-control/may-access' + '/' + resourceId.assignmentId + '/' + resourceId.creationTimestamp + '/' + resourceId.uuid;
  }

  export function mayAccessPublic(resourceId: ResourceId) {
    return environment.apiPath + '/access-control/may-access-public' + '/' + resourceId.assignmentId + '/' + resourceId.creationTimestamp + '/' + resourceId.uuid;
  }

  export function writePermission(communityId: RootResourceId) {
    return environment.apiPath + '/write-permission' + '/' + communityId.creationTimestamp + '/' + communityId.uuid;
  }

  export function userRightsByCommunity(communityId: RootResourceId) {
    return environment.apiPath + '/user-rights-by-community' + '/' + communityId.creationTimestamp + '/' + communityId.uuid;
  }

  export function purchaseBundle() {
    return environment.apiPath + '/sales/purchase/bundle';
  }

  export function freeMembership() {
    return environment.apiPath + '/sales/free/membership';
  }

  export function purchaseMembership() {
    return environment.apiPath + '/sales/purchase/membership';
  }

  export function cancelSubscription() {
    return environment.apiPath + '/sales/cancel-subscription';
  }

  export function reactivateSubscription() {
    return environment.apiPath + '/sales/reactivate-subscription';
  }

  export function membershipSubscriptions(){
    return environment.apiPath + '/user/state/membership-subscriptions';
  }

  export function bundleSubscriptions(){
    return environment.apiPath + '/user/state/bundle-subscriptions';
  }

  export function bundlePurchases(){
    return environment.apiPath + '/user/state/bundle-purchases';
  }
}
