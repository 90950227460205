import { inject, Injectable } from '@angular/core';
import { UserApiService } from './user-api.service';
import { InviteUser } from './domain/invite-user';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserApi } from './user-api';

@Injectable({
  providedIn: 'root'
})
export class UserApiServiceImplementation extends UserApiService {
  private readonly httpClient = inject(HttpClient);

  override invite(userInviteData: InviteUser): Observable<void> {
    return this.httpClient.post<void>(UserApi.invite, userInviteData);
  }
}
