<div class="modal fade text-white" id="loginModal" #loginModal tabindex="-1" aria-labelledby="loginModalLabel">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="loginModalLabel">{{ modalTitle }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-login [isSignUpMode]="loginMetaData? loginMetaData.isSignUpMode : true" (loginSuccess)="onLoginSuccess()" (modalTitle)="onModalTitleChange($event)"></app-login>
      </div>
    </div>
  </div>
</div>
