import {inject, Injectable} from '@angular/core';
import {UserContentApiService} from './user-content-api.service';
import {Observable, tap} from 'rxjs';
import {Community, CommunityProfile} from '../../community/domain/community-profile';
import {ResourceId, RootResource, RootResourceId} from '../../community/domain/resource';
import {ContentItem} from './domain/content-item';
import {HttpClient} from '@angular/common/http';
import {UserApi} from './user-api';
import {ContentItemWithCommunity} from "../../secure/domain/content-item-with-community";
import {CommunityApi} from '../../community/community.api';
import {CommunityRoutes} from '../../community/community.routes';
import {environment} from '../../../environments/environment';
import {StripeCheckoutData} from './domain/stripe-checkout-data';
import {SubscriptionPlan} from '../membership/domain/subscription-plan';
import { CommunityDelegateHttpService } from '../../community/access-control/community-delegate-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserContentApiServiceImplementation extends UserContentApiService {
  private readonly httpClient = inject(HttpClient);
  private readonly communityHttpClient = inject(CommunityDelegateHttpService);

  override getFollowedContent(): Observable<ContentItemWithCommunity[]> {
    return this.httpClient.get<ContentItemWithCommunity[]>(UserApi.getContent);
  }

  override getFollowedCommunities(): Observable<RootResource<CommunityProfile>[]> {
    return this.httpClient.get<RootResource<CommunityProfile>[]>(UserApi.getFollowedCommunities);
  }

  override setUserOpenedFeed(resource: ResourceId): Observable<void> {
    return this.communityHttpClient.post<void>(UserApi.followFeed, resource);
  }

  override setUserOpenedCollection(resource: ResourceId): Observable<void> {
    return this.communityHttpClient.post<void>(UserApi.followCollection, resource);
  }

  override getCommunitiesWithCreatorRights(): Observable<RootResource<CommunityProfile>[]> {
    return this.httpClient.get<RootResource<CommunityProfile>[]>(UserApi.getContributingCommunities);
  }

  override joinFreeCommunitySubscription(productId: ResourceId, community: RootResourceId): Observable<void> {
    return this.communityHttpClient.post<void>(CommunityApi.freeMembership(), community);
  }

  override purchaseCommunitySubscription(productId: ResourceId, offeringPriceUuid: string | null, plan: SubscriptionPlan, community: RootResourceId): Observable<StripeCheckoutData> { // todo move to other community related service
    const purchaseData = {
      productId,
      priceId: offeringPriceUuid,
      // termsUrl: '',
      successUrl: environment.hostPath + CommunityRoutes.membership(community.creationTimestamp, community.uuid, {purchaseSuccess: (SubscriptionPlan as any)[plan]}),
      cancelUrl: environment.hostPath + CommunityRoutes.membership(community.creationTimestamp, community.uuid)
    }

    return this.communityHttpClient.post<StripeCheckoutData>(CommunityApi.purchaseMembership(), purchaseData);
  }

  override purchaseBundle(productId: ResourceId, offeringPriceUuid: string | null, community: RootResourceId): Observable<StripeCheckoutData> { // todo move to other community related service
    const purchaseData = {
      productId,
      priceId: offeringPriceUuid,
      // termsUrl: '',
      successUrl: environment.hostPath + CommunityRoutes.product(community.creationTimestamp, community.uuid,
        productId.creationTimestamp, productId.uuid, 'true'),
      cancelUrl: environment.hostPath + CommunityRoutes.product(community.creationTimestamp, community.uuid,
        productId.creationTimestamp, productId.uuid)
    };

    return this.communityHttpClient.post<StripeCheckoutData>(CommunityApi.purchaseBundle(), purchaseData);
  }
}
