import { Product } from '../api/product/domain/product';
import { Period } from '../api/product/domain/period';
import { Resource } from '../community/domain/resource';

export class ProductUtil {
  static mapToBackendPrice(product: Product<any>): Product<any> {
    product.offerings[0].prices.forEach((price) => {
      if (price.period === Period.year) {
        price.amount = price.amount * 12 * 100;
      } else {
        price.amount = price.amount * 100;
      }
    })
    product.offerings[0].prices = Object.fromEntries(product.offerings[0].prices) as any;

    return product;
  }

  static mapToFrontendPrice(product: Resource<Product<any>>): Resource<Product<any>> {
    if (product.data.offerings?.length && product.data.offerings[0].prices) {
      product.data.offerings[0].prices = new Map(Object.entries(product.data.offerings[0].prices));
      product.data.offerings[0].prices.forEach((price) => {
        if (price.period === Period.year) {
          price.amount = price.amount / 12 / 100;
        } else {
          price.amount = price.amount / 100;
        }
      })
    }
    return product;
  }
}
