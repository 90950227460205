import { CommunityUserRole } from '../api/user/domain/community-user-role';
import { SubscriptionPlan } from '../api/membership/domain/subscription-plan';
import { UserRightsByCommunity } from '../api/resource/domain/user-rights-by-community';

export class CommunityUtil {
  static getCommunityUserRole(userRightsByCommunity: UserRightsByCommunity): CommunityUserRole {
    if (userRightsByCommunity.hasWritePermission) {
      return  CommunityUserRole.creator;
    } else if (userRightsByCommunity.subscriptionPlan === SubscriptionPlan.FREE) {
      return  CommunityUserRole.freeFollower;
    } else if (userRightsByCommunity.subscriptionPlan === SubscriptionPlan.BASIC) {
      return  CommunityUserRole.basicFollower;
    } else if (userRightsByCommunity.subscriptionPlan === SubscriptionPlan.PREMIUM) {
      return  CommunityUserRole.premiumFollower;
    }

    return CommunityUserRole.visitor;
  }
}
