import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {properties} from "../properties";

// todo: this is not clean
@Injectable({
  providedIn: 'root'
})
export class LegalService {

  licenseLinkByProductName: Map<string, string>;

  constructor() {
    this.licenseLinkByProductName = new Map<string, string>();
    this.licenseLinkByProductName.set("Personal License", environment.hostPath + "/legal#" + properties.goldContractAnchor)
    this.licenseLinkByProductName.set("Redistribution License", environment.hostPath + "/legal#" + properties.platinumContractAnchor)
  }

  public licenseLink(productName: string): string | undefined {
    return this.licenseLinkByProductName.get(productName);
  }
}
