import { FileUploadService } from './file-upload.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FullDelegate } from '../domain/instant';
import { accessDelegateHeader, Header } from '../../api/resource/resource-access.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadServiceImplementation extends FileUploadService {

  constructor(private http: HttpClient) {
    super();
  }

  uploadFile(file: File, delegate?: FullDelegate | null): Observable<{ url: string }> {
    const idPath = delegate ? '/' + delegate.uuid : '';
    const delegateHeader: Header | undefined = delegate ? accessDelegateHeader(delegate) : undefined;

    const path = `${environment.apiPath}/files${idPath}`;

    // Construct headers
    let headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'X-File-Name': encodeURIComponent(file.name),
      'X-File-Type': file.type
    });

    // Add delegate header if it exists
    if (delegateHeader) {
      headers = headers.set(delegateHeader.key, delegateHeader.value);
    }

    // Use HttpClient to upload the file via PUT
    return this.http.put<{ url: string }>(path, file, {headers});
  }
}
