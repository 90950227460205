import {Injectable} from "@angular/core";
import {PostService} from "./post.service";
import {Post} from "../domain/post";
import {Observable} from "rxjs";
import {Resource, ResourceId} from "../domain/resource";
import {Instant} from "../domain/instant";
import {CommunityApi} from "../community.api";
import {ContentDelegateHttpService} from "../access-control/content-delegate-http.service";

@Injectable({
  providedIn: 'root'
})
export class PostServiceImplementation extends PostService {

  constructor(private httpClient: ContentDelegateHttpService) {
    super();
  }

  create(assignmentId: string, post: Post): Observable<Resource<Post>> {
    return this.httpClient.post<Resource<Post>>(CommunityApi.post(assignmentId), post);
  }

  get(reference: ResourceId): Observable<Resource<Post>> {
    return this.httpClient.get<Resource<Post>>(CommunityApi.getPost(reference));
  }

  getSlice(instant: Instant, count: number, ascending: boolean): Observable<Resource<Post>[]> {
    return this.httpClient.get<Resource<Post>[]>(
      CommunityApi.posts(instant.assignmentId, instant.timestamp),
      {
        params: {
          count: count,
          ascending: ascending
        }
      }
    );
  }

  save(resource: Resource<Post>): Observable<Resource<Post>> {
    return this.httpClient.put<Resource<Post>>(CommunityApi.getPost(resource), resource.data);
  }
}
