import {inject, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {combineLatest, filter, map, switchMap, tap} from 'rxjs';
import {CommunityActions} from '../../store/community/community.actions';
import {AppRoutes} from '../../app.routes';
import {ResourceUtil} from '../../utils/resource-util';
import {AuthenticationService} from '../../authentication/authentication.service';
import {RouteUtil} from '../../utils/route-util';

@Injectable({
  providedIn: 'root'
})
export class CommunityRouteActionService {
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly store = inject(Store);
  private readonly authenticationService = inject(AuthenticationService);

  constructor() {
    this.listenToRouteChanges();
  }

  private listenToRouteChanges(): void {
    combineLatest([
      this.authenticationService.isAuthenticated(),
      this.router.events
        .pipe(
          filter((event): event is NavigationEnd => event instanceof NavigationEnd),
          filter((event: NavigationEnd) => this.isCommunityBasePath(event.urlAfterRedirects)),
          map(() => RouteUtil.getRootRouteWithParameters(this.route)),
          filter((route: ActivatedRoute) => route.outlet === PRIMARY_OUTLET)
        )
    ])
      // todo: we do this to avoid falsely assuming login, which now happens all the time due to the intentional delay
      //  in FirebaseAuthenticationService
      .pipe(switchMap(([isAuthenticated, route]) => this.authenticationService
        .isAuthenticated().pipe(tap(isAuthenticated => {
          if (isAuthenticated) {
            this.dispatchCommunityUserRoleAction(route);
          }
        }))))
      .subscribe();
  }

  private isCommunityBasePath(url: string): boolean {
    return url.split('/')[1] === AppRoutes.community;
  }

  private dispatchCommunityUserRoleAction(route: ActivatedRoute): void {
    const rootResourceId = ResourceUtil.getRootResourceIdFromUrl(route.snapshot);
    if (rootResourceId) {
      this.store.dispatch(CommunityActions.loadCommunityUserRole({communityId: rootResourceId}));
    }
  }
}
