<!--<app-staging-banner></app-staging-banner>-->
<div id="outer" class="d-flex flex-column overflow-hidden" data-bs-theme="dark" style="height: 100dvh">
<!--  <header id="header" class="flex-shrink-0" (click)="toggleCollapsed()">-->
    <header id="header" class="flex-shrink-0" >
    <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: var(--bs-primary);">
      <div class="container-fluid d-flex flex-row">

<!--        <button class="navbar-toggler" type="button" data-toggle="collapse"-->
<!--                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"-->
<!--                aria-expanded="false" aria-label="Toggle navigation">-->
<!--          <span class="navbar-toggler-icon"></span>-->
<!--        </button>-->

        <!-- Navbar links -->
<!--        todo: keeping the collapse is kind of a hack, but otherwise content will be at the start-->
<!--        <div class="justify-content-end" id="navbarSupportedContent" [ngClass]="{'collapse': navbarCollapsed, 'navbar-collapse': true}">-->
        <div class="justify-content-end" id="navbarSupportedContent" [ngClass]="{'collapse': false, 'navbar-collapse': true}">
<!--          <div class="justify-content-end" id="navbarSupportedContent" >-->
          <ul class="navbar-nav flex-row justify-content-end">
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center" [routerLink]="AppRoutes.community + '/profile'">
                <span class="material-symbols-rounded notranslate mx-1">explore</span>
<!--                <span class="d-none d-md-inline">Discover</span>-->
                                Discover
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center" [routerLink]="AppRoutes.myContent" routerLinkActive="nav-link-active" ariaCurrentWhenActive="page">
                <span class="material-symbols-rounded notranslate mx-1">account_circle</span>
<!--                <span class="d-none d-md-inline">My Space</span>-->
                My Space
              </a>
            </li>
            <!--            <li class="nav-item">-->
            <!--              <a class="nav-link" routerLink="/feed-creator" routerLinkActive="nav-link-active" ariaCurrentWhenActive="page">FeedCreator</a>-->
            <!--            </li>-->
            <!--            <li class="nav-item">-->
            <!--              <a class="nav-link" routerLink="/plans" routerLinkActive="nav-link-active" ariaCurrentWhenActive="page">Plans</a>-->
            <!--            </li>-->
            <!--            <li class="nav-item">-->
            <!--              <a class="nav-link" [routerLink]="AppRoutes.legal" routerLinkActive="nav-link-active" ariaCurrentWhenActive="page">Legal</a>-->
            <!--            </li>-->
            <li class="nav-item">
              @if(!isAuthenticated()){
               <button class="btn d-flex align-items-center px-0 px-md-2" (click)="loginModalService.login()">
                  <span class="material-symbols-rounded notranslate mx-1">login</span>
                  <span class="d-none d-md-inline">Login</span>
<!--                  Login-->
                </button>
              } @else {
                <button class="btn d-flex align-items-center" (click)="authenticationService.logoutAndRoute()">
                  <span class="material-symbols-rounded notranslate mx-1">logout</span>
                  <span class="d-none d-md-inline">Logout</span>
<!--                  Logout-->
                </button>
              }
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <main class="d-flex flex-grow-1 overflow-hidden">
    <router-outlet></router-outlet>
  </main>
  <app-login-modal></app-login-modal>
  <app-checkout-modal></app-checkout-modal>
  <app-loading></app-loading>
  <app-action-confirmation-modal></app-action-confirmation-modal>
</div>
