import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { AppState } from './app-state';
import { communityReducer } from './community/community.reducers';

export const appReducers: ActionReducerMap<AppState> = {
  // user: combineReducers({
  //   // machine: machineReducers,
  //   // manualMode: manualModeReducers
  // }),
  community: communityReducer,
  // router: routerReducer  // enable if @ngrx/router-store is used
};
