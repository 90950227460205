import {Injectable} from "@angular/core";
import {ProductService} from "./product.service";
import { map, Observable } from "rxjs";
import {Resource, ResourceId} from "../domain/resource";
import {Instant} from "../domain/instant";
import {CommunityApi} from "../community.api";
import {CommunityDelegateHttpService} from "../access-control/community-delegate-http.service";
import { Bundle } from '../../api/product/domain/bundle';
import { Product } from '../../api/product/domain/product';
import { MayAccess } from '../../api/resource/domain/may-access';
import { ProductUtil } from '../../utils/product-util';

@Injectable({
  providedIn: 'root'
})
export class ProductServiceImplementation extends ProductService {

  constructor(private httpClient: CommunityDelegateHttpService) {
    super();
  }

  override create(assignmentId: string, product: Product<Bundle>): Observable<Resource<Product<Bundle>>> {
    product = ProductUtil.mapToBackendPrice(product);
    return this.httpClient.post<Resource<Product<Bundle>>>(CommunityApi.createBundleProduct(assignmentId), product);
  }

  override get(reference: ResourceId): Observable<Resource<Product<Bundle>>> {
    return this.httpClient.get<Resource<Product<Bundle>>>(CommunityApi.getBundleProduct(reference))
      .pipe(
        map(product => ProductUtil.mapToFrontendPrice(product))
      );
  }

  override getSlice(instant: Instant, count: number, ascending: boolean): Observable<Resource<Product<Bundle>>[]> {
    return this.httpClient.get<Resource<Product<Bundle>>[]>(
      CommunityApi.getBundleProducts(instant.assignmentId, instant.timestamp),
      {params: {ascending: ascending}}
    )
      .pipe(
        map(products => {
          return products.map(product => ProductUtil.mapToFrontendPrice(product))
        })
      );
  }

  override save(resource: Resource<Product<Bundle>>): Observable<Resource<Product<Bundle>>> {
    return this.httpClient.put<Resource<Product<Bundle>>>(
      CommunityApi.putBundleProduct(resource.assignmentId, resource.creationTimestamp, resource.uuid),
      ProductUtil.mapToBackendPrice(resource.data)
    );
  }

  override mayPurchaseProduct(resourceId: ResourceId): Observable<MayAccess> {
    return this.httpClient.get<MayAccess>(CommunityApi.mayPurchaseProduct(resourceId));
  }

  override getProductsContainingResource(resourceId: ResourceId): Observable<Resource<Product<Bundle>>[]> {
    return this.httpClient.get<Resource<Product<Bundle>>[]>(CommunityApi.productByContent(resourceId))
      .pipe(
        map(products => {
          return products.map(product => ProductUtil.mapToFrontendPrice(product))
        })
      );
  }
}
