import {Injectable} from "@angular/core";
import {AbstractDelegateHttpService} from "./abstract-delegate-http.service";
import {HttpClient} from "@angular/common/http";
import {ResourceAccessService} from "../../api/resource/resource-access.service";
import {Observable, take} from "rxjs";
import {FullDelegate} from "../domain/instant";

@Injectable({
  providedIn: 'root'
})
export class ContentDelegateHttpService extends AbstractDelegateHttpService {

  constructor(
    httpClient: HttpClient,
    private resourceAccessService: ResourceAccessService
  ) {
    super(httpClient);
  }

  protected getDelegate(): Observable<FullDelegate> {
    return this.resourceAccessService.getContentDelegate();
  }
}
