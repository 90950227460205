import {Injectable} from "@angular/core";
import {ProfileService} from "./profile.service";
import {CommunityProfile} from "../domain/community-profile";
import {Observable} from "rxjs";
import {CommunityApi} from "../community.api";
import {HttpClient} from "@angular/common/http";
import {RootResource, RootResourceId} from "../domain/resource";

@Injectable({
  providedIn: 'root'
})
export class ProfileServiceImplementation extends ProfileService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  create(profile: CommunityProfile): Observable<RootResource<CommunityProfile>> {
    return this.httpClient.post<RootResource<CommunityProfile>>(CommunityApi.createCommunityProfile(), profile);
  }

  get(reference: RootResourceId): Observable<RootResource<CommunityProfile>> {
    return this.httpClient.get<RootResource<CommunityProfile>>(CommunityApi.getCommunityProfile(reference.creationTimestamp, reference.uuid));
  }

  getSlice(creationTimestamp: number, count: number, ascending: boolean): Observable<RootResource<CommunityProfile>[]> {
    return this.httpClient.get<RootResource<CommunityProfile>[]>(
      CommunityApi.getCommunityProfiles(),
      {params: {ascending: ascending}}
    );
  }

  save(resource: RootResource<CommunityProfile>): Observable<RootResource<CommunityProfile>> {
    return this.httpClient.put<RootResource<CommunityProfile>>(
      CommunityApi.putCommunityProfile(resource.creationTimestamp, resource.uuid),
      resource.data
    );
  }
}
