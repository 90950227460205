<form [formGroup]="credentialsForm" (ngSubmit)="onSubmit()" class="container mt-3">

  <div class="mb-4">

    @if (isForgotPasswordMode) {
      <p>
        Bitte gibt die Email-Adresse an, mit der Du dich ursprünglich angemeldet hast. <br>
        Nach Abschluss bekommst Du eine Email mit dem Link, um dein Passwort zurück zu setzen.
      </p>
    } @else {
      @if (isSignUpMode) {
        <p>
          Herzlich willkommen!<br>
        </p>
        <p>
          <!--          todo: use logo-->
          Der angefragte Inhalt wird von <span><img src="assets/images/leonardo-logo-dark.svg" style="height: 0.8em; vertical-align: baseline;"></span> - Creative Communities verwaltet.
          Bitte erstelle zunächst einen Account und/oder logge Dich ein. So können wir sicher
          stellen, dass nur Du Zugriff auf deine Inhalte hast.
        </p>
        <p>
          Zum Abschluss der Anmeldung erhältst Du eine Email. Bitte folge dem Link in der Email, um Deine
          Identität zu bestätigen.
        </p>
        <div class="text-end text-body-secondary">
          <a
            href="/privacy"
            target="_blank"
            rel="noopener noreferrer">
            Privacy
          </a>
        </div>
      } @else {
        <!--        <p>-->
          <!--          Herzlich willkommen!<br>-->
          <!--        </p>-->
<!--        <p>-->
<!--          Bitte melde Dich zunächst an, um fortzufahren.-->
<!--        </p>-->
      }
    }
  </div>

  @if (isSignUpMode) {
    <div class="form-group mb-3">
      <label for="firstName">First Name</label>
      <input id="firstName" type="text" formControlName="firstName" class="form-control">
    </div>
    <div class="form-group mb-3">
      <label for="name">Name</label>
      <input id="name" type="text" formControlName="name" class="form-control">
    </div>
  }

  <div class="form-group mb-3">
    <label for="email">Email *</label>
    <input id="email" type="email" formControlName="email" required class="form-control">
  </div>

  @if (!isForgotPasswordMode) {
    <div class="form-group">
      <label for="password">Password *</label>
      @if (isSignUpMode) {
        <div class="password-caption text-body-secondary mb-2">Choose a password that is at least 6
          characters long
        </div>
      }
      <input id="password" type="password" formControlName="password" required class="form-control">
      @if (!isSignUpMode) {
        <div class="d-flex justify-content-end">
          <button type="reset" class="btn btn-link" (click)="onForgotPasswordClicked()">
            Passwort zurücksetzen
            <!--        Forgot Password?-->
          </button>
        </div>
      }
    </div>
  }
  <div class="pt-5">

    <div *ngIf="!isSignUpMode && !isForgotPasswordMode">
      <!--    Don't have an account yet?-->
      <span style="font-weight: bold">Du hast noch keinen Account?</span>
    </div>
    <div *ngIf="isSignUpMode && !isForgotPasswordMode">
      <!--    Already have an account?-->
      <span style="font-weight: bold">Du hast bereits einen Account?</span>
    </div>
    <div class="d-flex justify-content-between pb-3">
      <button *ngIf="!isSignUpMode && !isForgotPasswordMode" type="button" class="btn btn-link" style="color: var(--hover-color)" (click)="toggleMode()">
        Zur Registrierung
        <!--      Switch to Sign Up-->
      </button>
      <button *ngIf="isSignUpMode && !isForgotPasswordMode" type="button" class="btn btn-link" style="color: var(--hover-color)" (click)="toggleMode()">
      Zum Login
        <!--      Switch to Log In-->
      </button>
      <button *ngIf="isForgotPasswordMode" type="button" class="btn btn-link" (click)="onResetPasswordBackClicked()">
        Zurück
<!--        Back-->
      </button>
      <button type="submit" class="btn btn-primary" [disabled]="!credentialsForm.valid">
        {{ isForgotPasswordMode ? 'Reset Password' : isSignUpMode ? 'Sign Up' : 'Log In' }}
      </button>
    </div>

    <div *ngIf="signupSuccess" class="alert alert-success animated fadeIn">
      Thank you, you are registered successfully. You will receive a verification email shortly. Please, click on the
      link in the email to complete sign up.
    </div>

    <div *ngIf="resetPasswordSuccess" class="alert alert-success animated fadeIn">
      You successfully initialized the password reset. You will receive an email shortly. Please follow the
      instructions in the email to create a new password.
    </div>

    <div *ngIf="signupError" class="alert alert-danger animated shake">
      We're very sorry, we're having some technical difficulties signing you up. Please contact us by email, and we'll
      sort out the problem: <a href="mailto:contact@keldysh.io">contact&#64;keldysh.io</a> <br><br>
      Detailed error: {{ signupError }}
    </div>

    <div *ngIf="loginError" class="alert alert-danger animated shake">
      {{ loginError }}
    </div>

    <div *ngIf="forgotPasswordError" class="alert alert-danger animated shake">
      {{ forgotPasswordError }}
    </div>
  </div>
</form>

