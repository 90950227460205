import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommunityActions } from './community.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { ResourceAccessService } from '../../api/resource/resource-access.service';

@Injectable()
export class CommunityEffects {
  private readonly actions = inject(Actions);
  private readonly resourceAccessService = inject(ResourceAccessService);

  loadCommunityUserRole = createEffect(() => {
    return this.actions.pipe(
      ofType(CommunityActions.loadCommunityUserRole),
      switchMap(({communityId}) => this.resourceAccessService.getUserRightsByCommunity(communityId)
        .pipe(
          map(userRightsByCommunity => CommunityActions.loadCommunityUserRoleSuccess({userRightsByCommunity})),
          catchError(error => of(CommunityActions.loadCommunityUserRoleError()))
        ))
    )
  })
}
