import {Injectable} from '@angular/core';
import {CommunitySubscriptionService} from './community-subscription.service';
import { map, Observable } from 'rxjs';
import {CommunityMembership} from '../../api/membership/domain/community-membership';
import {Instant} from '../domain/instant';
import {Resource, ResourceId} from '../domain/resource';
import {Product} from '../../api/product/domain/product';
import {CommunityApi} from "../community.api";
import {HttpClient} from "@angular/common/http";
import { CommunityDelegateHttpService } from '../access-control/community-delegate-http.service';
import { Period } from '../../api/product/domain/period';
import { ProductUtil } from '../../utils/product-util';

@Injectable({
  providedIn: 'root'
})
export class CommunitySubscriptionServiceImplementation extends CommunitySubscriptionService {

  public constructor(private httpClient: CommunityDelegateHttpService) {
    super();
  }

  override create(assignmentId: string, product: Product<CommunityMembership>): Observable<Resource<Product<CommunityMembership>>> {
    product = ProductUtil.mapToBackendPrice(product);
    return this.httpClient.post<Resource<Product<CommunityMembership>>>(CommunityApi.createMembershipProduct(assignmentId), product);
  }

  override get(reference: ResourceId): Observable<Resource<Product<CommunityMembership>>> {
    return this.httpClient.get<Resource<Product<CommunityMembership>>>(CommunityApi.getMembershipProduct(reference))
      .pipe(
        map(product => ProductUtil.mapToFrontendPrice(product))
      );
  }

  override getSlice(instant: Instant, count: number, ascending: boolean): Observable<Resource<Product<CommunityMembership>>[]> {
    return this.httpClient.get<Resource<Product<CommunityMembership>>[]>(
      CommunityApi.getMembershipProducts(instant.assignmentId, instant.timestamp),
      {params: {ascending: ascending}}
    )
      .pipe(
        map(subscriptions => {
          return subscriptions.map(product => ProductUtil.mapToFrontendPrice(product))
        })
      );
  }

  override save(resource: Resource<Product<CommunityMembership>>): Observable<Resource<Product<CommunityMembership>>> {
    return this.httpClient.put<Resource<Product<CommunityMembership>>>(
      CommunityApi.putMembershipProduct(resource.assignmentId, resource.creationTimestamp, resource.uuid),
      ProductUtil.mapToBackendPrice(resource.data)
    );
  }
}
