import {Injectable} from "@angular/core";
import {FeedService} from "./feed.service";
import {Feed} from "../domain/feed";
import {Observable} from "rxjs";
import {Resource, ResourceId} from "../domain/resource";
import {Instant} from "../domain/instant";
import {CommunityApi} from "../community.api";
import {CommunityDelegateHttpService} from "../access-control/community-delegate-http.service";

@Injectable({
  providedIn: 'root'
})
export class FeedServiceImplementation extends FeedService {

  constructor(private httpClient: CommunityDelegateHttpService) {
    super();
  }

  create(assignmentId: string, feed: Feed): Observable<Resource<Feed>> {
    return this.httpClient.post<Resource<Feed>>(CommunityApi.createFeed(assignmentId), feed);
  }

  get(reference: ResourceId): Observable<Resource<Feed>> {
    return this.httpClient.get<Resource<Feed>>(CommunityApi.getFeed(reference));
  }

  getSlice(instant: Instant, count: number, ascending: boolean): Observable<Resource<Feed>[]> {
    return this.httpClient.get<Resource<Feed>[]>(
      CommunityApi.getFeeds(instant.assignmentId, instant.timestamp),
      {params: {ascending: ascending}}
    );
  }

  save(resource: Resource<Feed>): Observable<Resource<Feed>> {
    return this.httpClient.put<Resource<Feed>>(
      CommunityApi.putFeed(resource.assignmentId, resource.creationTimestamp, resource.uuid),
      resource.data
    );
  }
}
