import {Injectable} from '@angular/core';
import {ResourceId, RootResourceId} from '../../community/domain/resource';
import {catchError, Observable, of, switchMap, tap} from 'rxjs';
import {CommunityApi} from '../../community/community.api';
import {AccessLevels, AccessSetupData, ResourceAccessService} from './resource-access.service';
import {HttpClient} from '@angular/common/http';
import {ProfileService} from '../../community/profile/profile.service';
import {UserRightsByCommunity} from './domain/user-rights-by-community';
import {MayAccess} from './domain/may-access';
import {AuthenticationService} from "../../authentication/authentication.service";
import {ModalState} from "../../action-confirmation-modal/action-confirmation-modal.service";

@Injectable({
  providedIn: 'root',
})
export class ResourceAccessServiceImplementation extends ResourceAccessService {

  constructor(
    private httpClient: HttpClient,
    profileService: ProfileService,
    private authenticationService: AuthenticationService
  ) {
    super(profileService);
  }

  setAccessLevel(resourceId: ResourceId, accessLevel: AccessLevels) {
    return this.profileService
      .activeCommunity()
      .pipe(switchMap(communityProfileResource => {
          const body: AccessSetupData = {communityId: communityProfileResource, accessLevel: accessLevel};
          return this.setupAccessLevel(resourceId, body);
        }
      ))
  }

  getAccessLevel(resourceId: ResourceId): Observable<AccessSetupData> {
    return this.httpClient.get<AccessSetupData>(CommunityApi.accessControl(resourceId))
  }

  getMayAccess(resourceId: ResourceId): Observable<MayAccess> {
    return this.httpClient.get<MayAccess>(CommunityApi.mayAccess(resourceId))
  }

  getMayAccessPublic(resourceId: ResourceId): Observable<MayAccess> {
    return this.httpClient.get<MayAccess>(CommunityApi.mayAccessPublic(resourceId))
  }

  private setupAccessLevel(resourceId: ResourceId, accessSetupData: AccessSetupData) {
    return this.httpClient.put<void>(CommunityApi.accessControl(resourceId), accessSetupData)
  }

  checkCommunityWriteAccess(communityId: RootResourceId): Observable<boolean> {
    return this.authenticationService.isAuthenticated()
      .pipe(
        switchMap(isAuthenticated => {
          return isAuthenticated
            ? this.httpClient
              .get<boolean>(CommunityApi.writePermission(communityId))
              .pipe(
                catchError((error) => {
                  if (error.status === 401) {
                    // console.error('Unauthorized (401): Redirecting to login or providing default data.');
                    return of(false);
                  }
                  throw error;
                })
              )
            : of(false)
        }),
        tap(mayWrite => this.mayWriteSubject.next(mayWrite))
      )
  }

  override getUserRightsByCommunity(communityId: RootResourceId): Observable<UserRightsByCommunity> {
    return this.httpClient.get<UserRightsByCommunity>(CommunityApi.userRightsByCommunity(communityId));
  }
}
