import { createReducer, on } from '@ngrx/store';
import { CommunityState } from './community-state';
import { CommunityUserRole } from '../../api/user/domain/community-user-role';
import { CommunityActions } from './community.actions';
import { SubscriptionPlan } from '../../api/membership/domain/subscription-plan';
import { CommunityUtil } from '../../utils/community-util';

const initialCommunityState: CommunityState = {
  activeCommunity: null,
  userRights: {
    role: CommunityUserRole.visitor,
    subscriptionPlan: SubscriptionPlan.NO_MEMBERSHIP
  }
}

export const communityReducer = createReducer<CommunityState>(
  initialCommunityState,
  on(CommunityActions.setActiveCommunity,
    (state: CommunityState, {communityId}): CommunityState => ({
      ...state,
      activeCommunity: {
        uuid: communityId.uuid,
        creationTimestamp: communityId.creationTimestamp
      }
    })),
  on(CommunityActions.leaveCommunity,
    (state: CommunityState): CommunityState => ({
      ...state,
      activeCommunity: null,
      userRights: {
        role: CommunityUserRole.visitor,
        subscriptionPlan: SubscriptionPlan.NO_MEMBERSHIP
      }
    })),

  on(CommunityActions.loadCommunityUserRole,
    (state: CommunityState): CommunityState => ({...state})),
  on(CommunityActions.loadCommunityUserRoleSuccess, (state: CommunityState, {userRightsByCommunity}): CommunityState => {
    return {
      ...state,
      userRights: {
        role: CommunityUtil.getCommunityUserRole(userRightsByCommunity),
        subscriptionPlan: userRightsByCommunity.subscriptionPlan
      }
    }
  }),
  on(CommunityActions.loadCommunityUserRoleError,
    (state: CommunityState): CommunityState => ({
      ...state, userRights: {
        role: CommunityUserRole.visitor,
        subscriptionPlan: SubscriptionPlan.NO_MEMBERSHIP
      }
    }))
)
