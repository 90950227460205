import {environment} from "../../../environments/environment";

const user = environment.apiPath + '/user';
const userState = user + '/state';

export const UserApi = {
  invite: user + '/invite',

  grantAccessToFeed: userState + '/grant-access/feed',
  grantAccessToCollection: userState + '/grant-access/collection',
  assignSubscription: userState + '/assign-subscription',
  addToCommunity: userState + '/add-to-community',

  getContributingCommunities: userState + '/contribute/communities',

  getFollowedCommunities: userState + '/follow/communities',
  getContent: userState + '/follow/content',
  followFeed: userState + '/follow/feed',
  followCollection: userState + '/follow/collection'
}
