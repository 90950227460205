import { createAction, props } from '@ngrx/store';
import { RootResourceId } from '../../community/domain/resource';
import { UserRightsByCommunity } from '../../api/resource/domain/user-rights-by-community';

export enum CommunityActionKeys {
  setActiveCommunity = '[Community] Set Active Community',
  leaveCommunity = '[Community] Leave Community',
  loadCommunityUserRole = '[Community] Load Community User Role',
  loadCommunityUserRoleSuccess = '[Community] Load Community User Role Success',
  loadCommunityUserRoleError = '[Community] Load Community User Role Error',
}

export class CommunityActions {
  static setActiveCommunity = createAction(CommunityActionKeys.setActiveCommunity, props<{
    communityId: RootResourceId
  }>());
  static leaveCommunity = createAction(CommunityActionKeys.setActiveCommunity, props<{
    communityId: RootResourceId
  }>());
  static loadCommunityUserRole = createAction(CommunityActionKeys.loadCommunityUserRole, props<{
    communityId: RootResourceId
  }>());
  static loadCommunityUserRoleSuccess = createAction(CommunityActionKeys.loadCommunityUserRoleSuccess, props<{
    userRightsByCommunity: UserRightsByCommunity
  }>());
  static loadCommunityUserRoleError = createAction(CommunityActionKeys.loadCommunityUserRoleError);
}
